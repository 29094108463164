module.exports = (function(){

	function smoothScrollTo(destination, parent, time) {
	    var scroll = init();
	    requestAnimationFrame(shouldScroll);

	    function init() {
	        var start = parent.scrollTop;
	        var ticks = time || 30;
	        var i = 0;
	        return {
	            positionY: function() {
	                return (destination - start) * i / ticks + start;
	            },
	            isFinished: function() {
	                return i++ >= ticks;
	            }
	        };
	    }

	    function shouldScroll() {
	        if (scroll.isFinished()) return;
	        parent.scrollTop = scroll.positionY();
	        requestAnimationFrame(shouldScroll);
	    }
	}

	return {
		smoothScrollTo: smoothScrollTo,
	}
})();