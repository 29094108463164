module.exports = (function(){

	function isInstanziable(element) {
        return element;
    }

	function hideElementWarning(element) {
	    element.style.display="none";
	}

	function showElementWarning(element) {
	    element.style.display="block";
	}

	function checkName(inputName, subscribeWarning, e) {
	    if(inputName.value == "") {
	        inputName.style.borderBottom="2px solid #f8c34c";
	        showElementWarning(subscribeWarning);
	        e.preventDefault();
	    }else {
	        inputName.style.borderBottom="1px solid #8c8c8c";
	        hideElementWarning(subscribeWarning);
	    }
	}

	function checkSurname(inputSurname, subscribeWarning, e) {
	    if(inputSurname.value == "") {
	        inputSurname.style.borderBottom="2px solid #f8c34c";
	        showElementWarning(subscribeWarning);
	        e.preventDefault();
	    }else {
	        inputSurname.style.borderBottom="1px solid #8c8c8c";
	        hideElementWarning(subscribeWarning);
	    }
	}

	function checkEmail(inputEmail, subscribeWarning, emailWarning, e) {
	    if(inputEmail.value === "" || !inputEmail.value) {
	        inputEmail.style.borderBottom="2px solid #f8c34c";
	        showElementWarning(subscribeWarning);
	        showElementWarning(emailWarning);
	        emailWarning.style.display="block";
	        e.preventDefault();
	    }else {
	        inputEmail.style.borderBottom="1px solid #8c8c8c";
	        hideElementWarning(subscribeWarning);
	        hideElementWarning(emailWarning);
	    }
	}

	function checkPrivacy(privacyCheckbox, privacyLabel, subscribeWarning, e) {
	    if(!privacyCheckbox.checked){
	        privacyLabel.style.borderBottom="2px solid #f8c34c";
	        showElementWarning(subscribeWarning);
	        e.preventDefault();
	        return false;
	    }else {
	        privacyLabel.style.borderBottom="0";
	        return true;
	    }
	}

	return {
		isInstanziable: isInstanziable,
		hideElementWarning: hideElementWarning,
		showElementWarning: showElementWarning,
		checkName: checkName,
		checkSurname: checkSurname,
		checkEmail: checkEmail,
		checkPrivacy: checkPrivacy
	}

})();
