var Util = require('../Util.js');
var Instafeed = require('./instafeed.min.js');

module.exports = (function(){
  function isInstanziable() {
      return Util.el('#instafeed');
  }

  function init(options) {
    const defaultOptions = {
      template: generateComponent(),
      resolution: 'low_resolution',
      limit: 6
    };
    return new Instafeed({...defaultOptions, ...options});
  }

  function onXHRHandler(payload, myOptions) {
    const isErrorCallback = typeof payload === 'string';
    const isSuccessCallback = payload && payload.meta && payload.meta.code === 200;

    if (isErrorCallback) {
      myOptions.productFeedTitle.style.display = 'none';
      // console.log(myOptions.productTitle.innerHTML + ' non ha foto.');
    } else if (isSuccessCallback) {
      // console.log('ooook!');
    }
  }

  function generateComponent() {
    return `
        <div class="g6">
          <a style="background-image:url({{image}});" href="{{link}}" target="_blank" rel="noopener">
            <div class="black-overlay"></div>
            <div class="insta-caption">
              <p>{{caption}}</p>
            </div>
          </a>
        </div>
      `;
  }

  return {
    isInstanziable: isInstanziable,
    init: init,
    onXHRHandler: onXHRHandler
  }
})();
