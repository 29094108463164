/********************** REQUIRE MODULES **************************/
import 'babel-polyfill';
import 'whatwg-fetch'

var Util = require('./Util.js');
var InstaFeedModule = require('./instaFeed/InstaFeedModule.js');
var SmoothScrollModule = require('./SmoothScrollModule.js');
var ErrorsNewsletterModule = require('./ErrorsNewsletterModule.js');
var Flickity = require('flickity');
window.algoliasearch = require('./algolia/algoliasearch.min.js');
window.Blazy = require('./blazy.js');

import autocomplete from 'autocomplete.js';

/********************** INITIALIZE VARIABLES **************************/

var homepageElement = Util.el('.isHome'); //h3.inside exists only in homepage
var searchResults = Util.el('#search-results');
var searchBar = Util.el('#searchBar');
var searchButton = Util.el('#searchButton');
var searchForm = Util.el('#searchForm');

var relatedWrapper = Util.el('.related-wrapper');

var scrollNode = document.scrollingElement || document.documentElement;


/********************** UTIL **************************/

Util.darkBody(searchResults);
Util.placeholderSearch(searchBar, searchButton);
Util.dynamicCopy(Util.el('.footer-copy'));

/********************** SEARCH PANEL AND HAMBURGER MENU **************************/

if (Util.el('.search-container') != null) {

  Util.el('#searchIconMobile').addEventListener('click', openCloseSearchPanel);
  Util.el('#closeSearch').addEventListener('click', openCloseSearchPanel);
  if (Util.el('.scroll-search')) {
    Util.el('.scroll-search').addEventListener('click', openCloseSearchPanel);
  }

  Util.el('#hamburger').addEventListener('click', openCloseMobileNav);
  Util.el('#closeMobileNav').addEventListener('click', openCloseMobileNav);
  Util.el('.opacize-body').addEventListener('click', openCloseMobileNav);
  if (Util.el('.scroll-nav')) {
    Util.el('.scroll-nav').addEventListener('click', openCloseMobileNav);
  }

  Util.el('.opacize-body').addEventListener('click', closeAllPanels);

  if (window.innerWidth < 480) {
    Util.el('#searchBar').setAttribute('placeholder', 'Search');
  }
}

function openCloseSearchPanel() {
  document.body.classList.toggle('disable-body');
  document.documentElement.classList.toggle('disable-body');
  Util.el('.opacize-body').classList.toggle('on');
  Util.el('.search-container').classList.toggle('on');
  Util.el('.search-container').classList.toggle('display');
}

function openCloseMobileNav() {
  document.body.classList.toggle('disable-body');
  document.documentElement.classList.toggle('disable-body');
  Util.el('.opacize-body').classList.toggle('on');
  Util.el('.nav-mobile').classList.toggle('on');
}

function closeAllPanels() {
  document.body.classList.remove('disable-body');
  document.documentElement.classList.remove('disable-body');
  Util.el('.opacize-body').classList.remove('on');
  Util.el('.search-container').classList.remove('on');
  Util.el('.search-container').classList.remove('display');
  Util.el('.nav-mobile').classList.remove('on');
}


/********************** VIDEO MAINSLIDER DISAPPEAR **************************/
const HOME_SLIDER_EUROPA_SELECTOR = '.slider-home-main-slider-international';
const HOME_SLIDER_AMERICA_SELECTOR = '.slider-home-main-slider-north-america';

const videoTagHome = document.querySelector(
  !Util.isLuceplanUsa() ? `${HOME_SLIDER_EUROPA_SELECTOR} .video-home` : `${HOME_SLIDER_AMERICA_SELECTOR} .video-home`
);
if (videoTagHome) {
  const sliders = document.querySelectorAll('div.main-carousel');
  for (let i = 0; i < sliders.length; i++) {
    sliders[i].style.display = "none";
  }
}


/********************** DESIGNERS SCRIPTS **************************/

// designer related products show / hide

var designerName = Util.els('.category-designer-name');

if (designerName.length > 0) {
  for (var i = 0; i < designerName.length; i++) {
    designerName[i].addEventListener('click', function (e) {
      e.preventDefault();
      this.children[0].classList.toggle('rotate-arrow');
      this.nextElementSibling.classList.toggle('show-designer-related-products');
    });
  }
}


/*********************** STICKY HEADER SECTION *********************************/

if (window.innerWidth > 991) {
  var header = Util.el("header");
  var headerSearch = Util.el("header.header-search-results");
  var headerLate = Util.el("header.header-late");
  var topIndicatorForDelay = Util.el("section");


  window.addEventListener('scroll', function () {
    var topForDelay = topIndicatorForDelay.getBoundingClientRect().top;
    if (homepageElement || headerLate) {
      if (topForDelay < 30) {
        header.classList.add('sticky-header');
        Util.el('svg#logo').style.fill = 'black';
        if (Util.el('.secondary-menu')) {
          Util.el('.secondary-menu').classList.add('appear');
        }
      } else {
        header.classList.remove('sticky-header');
        Util.el('svg#logo').removeAttribute("style");
        if (Util.el('.secondary-menu')) {
          Util.el('.secondary-menu').classList.remove('appear');
        }
      }
    } else {
      if (window.pageYOffset > 0) {
        header.classList.add('sticky-header');
        if (!headerSearch) {
          Util.el('svg#logo').style.fill = 'black';
        }

      } else {
        header.classList.remove('sticky-header');
        if (!headerSearch) {
          Util.el('svg#logo').removeAttribute("style");
        }
      }
    }

    /*********************** MAKE SECONDARY MENU STOP AT THE BOTTOM *********************************/
    /* BOTTOM LIMIT FOR PRODUCT DETAIL PAGE */
    if (relatedWrapper) {
      var bottomLimit = relatedWrapper.getBoundingClientRect().top;
      if (Util.el('.secondary-menu')) {
        if (bottomLimit < 265) {
          Util.el('.secondary-menu').style.position = "absolute";
        } else {
          Util.el('.secondary-menu').style.position = "fixed";
        }
      }
    }

    /* BOTTOM LIMIT FOR COMPANY PAGE */
    if (Util.el('#awards-box')) {
      var awardsLimit = Util.el('#awards-box').getBoundingClientRect().top;
      if (Util.el('.secondary-menu')) {
        if (awardsLimit < 163) {
          Util.el('.secondary-menu').style.position = "absolute";
        } else {
          Util.el('.secondary-menu').style.position = "fixed";
        }
      }
    }
  });
}


/*********************** FILTER SECTION *********************************/

var filtersBox = Util.el("article.filters");
var functionLink = document.getElementById('function-link');
var filtersBox1level = Util.el('#filters-box');
var filtersArrowFilters = Util.el('#filtersArrowFilters');
var plusFilter = Util.el('#filters-box .plusFilterProducts');
var selectOrder = Util.el('.order-products');

// sticky filters

if (filtersBox != null) {
  if (window.innerWidth < 991) {
    var offsetIndicator = Util.el("article.list");
    window.addEventListener('scroll', function () {
      // these are relative to the viewport, i.e. the window
      var top = offsetIndicator.getBoundingClientRect().top;

      if (top < 0) {
        filtersBox.classList.add('filter-fixed');
        selectOrder.classList.add('select-fixed');
      } else {
        filtersBox.classList.remove('filter-fixed');
        selectOrder.classList.remove('select-fixed');
      }
    });
  }

  if (window.innerWidth > 991) {
    filtersArrowFilters.style.display = "none";
    filtersBox1level.classList.toggle('show');
    if (plusFilter) {
      plusFilter.addEventListener('click', function (e) {
        e.preventDefault();
        plusFilter.classList.toggle('rotate-plus');
        Util.el('.filters-second-level').classList.toggle('filters-second-level-show');
        functionLink.classList.toggle('filter-selected');
      });
    }

  } else {
    filtersArrowFilters.style.display = "block";
    // Open first level filters
    filtersArrowFilters.addEventListener('click', function (e) {
      e.preventDefault();
      filtersBox1level.classList.toggle('show');
    });
  }
}

//function link section

if (functionLink != null) {
  // Open second level filters (Function link)
  functionLink.addEventListener('click', function (e) {
    e.preventDefault();
    plusFilter.classList.toggle('rotate-plus');
    Util.el('.filters-second-level').classList.toggle('filters-second-level-show');
    functionLink.classList.toggle('filter-selected');
  });
}


/*********************** MANAGER LUCEPLAN-LUCEPLANUSA *************************/

// cookie/privacy links

const footerSection = Util.el('footer section');

if (Util.isLuceplanUsa()) {
  const privacyCookieInternational = Util.els('.privacy-cookie-international');
  for (let i = 0; i < privacyCookieInternational.length; i++) {
    footerSection.removeChild(privacyCookieInternational[i]);
  }
} else {
  const privacyCookieUsa = Util.els('.privacy-cookie-usa');
  for (let i = 0; i < privacyCookieUsa.length; i++) {
    footerSection.removeChild(privacyCookieUsa[i]);
  }
}

// Countries menu with america

var international = Util.els('.international-link');
var italia = Util.els('.italia-link');
var northAmerica = Util.els('.north-america-link');

if (Util.isLuceplanUsa()) {
  for (var i = 0; i < international.length; i++) {
    international[i].classList.remove('selected');
    italia[i].classList.remove('selected');
    northAmerica[i].classList.add('selected');
  }
} else {
  for (var i = 0; i < international.length; i++) {
    international[i].classList.add('selected');
    italia[i].classList.remove('selected');
    northAmerica[i].classList.remove('selected');
  }
}

if (window.location.href.indexOf("/it/") > -1) {
  for (var i = 0; i < international.length; i++) {
    international[i].classList.remove('selected');
    italia[i].classList.add('selected');
    northAmerica[i].classList.remove('selected');
  }
}

/**************** CONTACT PAGE GENERAL CONTACTS DIVIDED FOR COUNTRY ***********************/

const generalContact = document.querySelector('.general-contacts');

if (generalContact) {
  if (Util.isLuceplanUsa()) {
    document.querySelector('.general-contacts').style.display = 'none';
    document.querySelector('.general-contacts--usa').style.display = 'flex';
    document.querySelector('.general-contacts__toll-free-number').style.display = 'none';
  } else {
    document.querySelector('.general-contacts--usa').style.display = 'none';
    document.querySelector('.general-contacts').style.display = 'flex';
    document.querySelector('.general-contacts__toll-free-number').style.display = 'block';
  }
}

// Homepage sliders

var sliderEurope = Util.el(HOME_SLIDER_EUROPA_SELECTOR);
var sliderAmerica = Util.el(HOME_SLIDER_AMERICA_SELECTOR);

if (homepageElement && sliderAmerica) {
  if (Util.isLuceplanUsa()) {
    sliderEurope.parentElement.removeChild(sliderEurope);
    sliderAmerica.style.opacity = '1';
  } else {
    sliderAmerica.parentElement.removeChild(sliderAmerica);
    sliderEurope.style.opacity = '1';
  }
} else {
  if (homepageElement) {
    sliderEurope.style.opacity = '1';
  }
}

// Data sheet slider in product detail page

var dataSheetEu = Util.el('#data-sheet-eu');
var dataSheetUs = Util.el('#data-sheet-us');
var productDetailSection = Util.el('#product-detail-section');

if (productDetailSection) {
  if (Util.isLuceplanUsa()) {
    if (dataSheetEu) {
      dataSheetEu.parentElement.removeChild(dataSheetEu);
    }
  } else {
    if (dataSheetUs) {
      dataSheetUs.parentElement.removeChild(dataSheetUs);
    }
  }
}

// Hide european products in luceplanusa version, in product list page

document.addEventListener('DOMContentLoaded', function () {
  deleteEuropeanProducts(function () {
    hideDesignersFamilies();
  })
  deleteOnlyUsaProducts(function () {
    hideDesignersFamilies();
  })
});

function deleteEuropeanProducts(callback) {
  var productElements = Util.els('.single-product');
  if (Util.isLuceplanUsa()) {
    var productsToHide = Array.prototype.filter.call(productElements, containHideClass);
    var productsToShow = Array.prototype.filter.call(productElements, notContainHideClass);

    for (var i = 0; i < productsToHide.length; i++) {
      productsToHide[i].parentElement.removeChild(productsToHide[i]);
    }

    for (var i = 0; i < productsToShow.length; i++) {
      productsToShow[i].style.opacity = '1';
    }
  } else {
    for (var i = 0; i < productElements.length; i++) {
      productElements[i].style.opacity = '1';
    }
  }
  callback();
}

function deleteOnlyUsaProducts(callback) {
  var productElements = Util.els('.single-product');
  if (!Util.isLuceplanUsa()) {
    var productsToHide = Array.prototype.filter.call(productElements, containOnlyUsaClass);

    for (var i = 0; i < productsToHide.length; i++) {
      productsToHide[i].parentElement.removeChild(productsToHide[i]);
    }
  }
  callback();
}

function containOnlyUsaClass(prod) {
  return prod.classList.contains('only-usa-product');
}

function containHideClass(prod) {
  return prod.classList.contains('hide-in-usa');
}

function notContainHideClass(prod) {
  return !prod.classList.contains('hide-in-usa');
}

// Hide products in luceplanusa version, in families and designers list pages

function hideDesignersFamilies() {
  var relatedProductsItemList = Util.els('.related-products-item');
  for (var i = 0; i < relatedProductsItemList.length; i++) {
    if (relatedProductsItemList[i].innerHTML.trim() == '') {
      var elToDelete = relatedProductsItemList[i].previousElementSibling;
      elToDelete.parentElement.removeChild(elToDelete);
      relatedProductsItemList[i].parentElement.removeChild(relatedProductsItemList[i]);
    }
  }
}


// Hide documents that are not related to USA
var elementsToHide = [
  Util.el('#brochure-int'),
  Util.el('#catalogue-int'),
  Util.el('#energy-label'),
  Util.el('#libraries'),
  Util.el('#photometric-data'),
  Util.el('#tech-sheets'),
  Util.el('#dialux')];

if (Util.isLuceplanUsa()) {
  if (elementsToHide[0]) {
    for (let i = 0; i < elementsToHide.length; i++) {
      if (elementsToHide[i] != null) {
        elementsToHide[i].style.display = "none";
      }
    }
  }
}

/*********************** PRODUCT DETAIL DOWNLOADS ONLY USA *************************/
if (Util.isLuceplanUsa()) {
  const usaOnlyFiles = document.querySelectorAll('.files-usa');
  if (usaOnlyFiles) {
    for (let i = 0; i < usaOnlyFiles.length; i++) {
      usaOnlyFiles[i].style.display = "inline-block"
    }
  }
} else {
  const internationalOnlyFiles = document.querySelectorAll('.files-international');
  if (internationalOnlyFiles) {
    for (let i = 0; i < internationalOnlyFiles.length; i++) {
      internationalOnlyFiles[i].style.display = "inline-block"
    }
  }
}


/*********************** ALGOLIA *************************/

// Algolia Autocomplete

var client = algoliasearch('5MX0NATW4V', '0d537e805572c8d17c1e59a8ae7cec57');
var index = client.initIndex('default');

var searchableCategories = [
  {type: 'products', title: 'Products', title_it: 'Prodotti'},
  {type: 'designers', title: 'Designers', title_it: 'Designers'},
  {type: 'projects', title: 'Projects', title_it: 'Progetti'},
  {type: 'news', title: 'News', title_it: 'News'}
];
var translationCategories = {
  products: 'prodotti',
  designers: 'i-designers',
  projects: 'progetti',
  news: 'notizie',
};

autocomplete('#searchBar', {hint: false, debug: true}, [{
  source: autocomplete.sources.hits(index, {hitsPerPage: 10, distinct: true}),
  displayKey: 'title',
  templates: {
    suggestion: function (suggestion) {
      const isSearchable = suggestion.published && (suggestion.collectionName || suggestion.collection);
      if (isSearchable) {
        return '<span>' + suggestion._highlightResult.title.value + '</span>';
      }
    }
  }
}]).on('autocomplete:selected', function (event, suggestion, dataset) {
  document.activeElement.blur();
  searchForm.submit();
});

// Algolia search filter

var pathnameEng = '/search';
var pathnameIta = '/it/cerca';
var weAreItalian = false;

if (window.location.pathname === pathnameEng || window.location.pathname === pathnameIta) {

  var url = window.location.search;
  url = url.substring(3, url.length);
  url = decodeURIComponent(url);

  index.search(url, {distinct: false}, onSearchDone);
}

function onSearchDone(err, content) {

  if (err) {
    return console.error('Error!')
  }

  var containerSearchResults = Util.el('#search-results');
  var statamicImagesAndFunctions = getStatamicImagesAndFunctions();

  var searchFor = JSON.parse(
    '{"' + decodeURI(content.params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
  );

  var searchForClean = searchFor.query;

  searchForClean = decodeURIComponent(searchForClean);
  searchForClean = Util.clearString(searchForClean);

  if (searchForClean == "") {
    if (window.location.pathname === pathnameIta) {
      containerSearchResults.innerHTML = '<h2>Fai una nuova ricerca usando il pannello in alto a destra.</h2>';
      weAreItalian = true;
    } else {
      containerSearchResults.innerHTML = '<h2>Make a new search using the top right panel.</h2>';
    }
  } else {
    doTheSearch(content, containerSearchResults, statamicImagesAndFunctions, searchForClean);

  }
}


function doTheSearch(content, containerSearchResults, statamicImagesAndFunctions, searchForClean) {

  searchForClean = searchForClean.replace(/%2B/g, " ");

  var labelYesNoResultsEng = statamicImagesAndFunctions.length === 0 ? 'No ' : 'Search ';
  var labelYesNoResultsIta = statamicImagesAndFunctions.length === 0 ? 'Nessun risultato ' : 'Risultati ';

  var searchResultsLabelEng = '<h2>' + labelYesNoResultsEng + ' Results for: ' + '<span>' + searchForClean + '</span>' + '</h2>';
  var searchResultsLabelIta = '<h2>' + labelYesNoResultsIta + ' per: ' + '<span>' + searchForClean + '</span>' + '</h2>';

  if (window.location.pathname === pathnameIta) {
    containerSearchResults.innerHTML = searchResultsLabelIta;
    weAreItalian = true;
  } else {
    containerSearchResults.innerHTML = searchResultsLabelEng;
  }

  searchableCategories.forEach(function (category) {

    var filteredElement = content.hits.filter(function (item) {
      var associateImage = statamicImagesAndFunctions.find(function (image) {
        return image.id === item.id
      })
      if (associateImage) {
        item.associateImage = associateImage;
      }
      return (item.collectionName || item.collection) === category.type && associateImage;
    }).map(function (el) {
      var fun = el.associateImage.functions ? el.associateImage.functions : '';
      var resFun = fun.split(" ");
      var firstFun = resFun[0];
      var title = el.title.toLowerCase();
      if (title.indexOf(" ") >= 0) {
        title = title.split(' ').join('-');
      }
      title = Util.clearStringFromAccents(title);
      var elCollection = (el.collectionName || el.collection);
      var itUrl = '/it/' + translationCategories[elCollection] + '/';
      if (category.type === 'products') {
        if (firstFun === 'Soffitto') {
          firstFun = 'Parete';
        }
        itUrl += title + '-' + firstFun.toLowerCase();
      } else {
        itUrl += el.slug;
      }
      return Component.Card({
        title: el.title || '',
        function: fun,
        url: el.url,
        locale: el.associateImage.locale,
        it_url: itUrl,
        id: el.id,
        coverImage: el.associateImage ? el.associateImage.src : '',
      })
    });

    if (filteredElement.length === 0) {
      return;
    }

    var outerHTMLArray = filteredElement.map(function (card) {
      return card.outerHTML;
    });

    var _results = Component.Results({
      title: filteredElement.length +
        ((weAreItalian) ?
          ' risultati in <span>' + category.title_it + '</span>' :
          ' results in <span>' + category.title + '</span>'),
      children: outerHTMLArray.join(' ')
    });

    containerSearchResults.insertAdjacentHTML('beforeEnd', _results.outerHTML);
  });
}


function getStatamicImagesAndFunctions() {
  var placeholders = Util.els('statamic-placeholder');

  var DOMElement = Array.prototype.filter.call(placeholders, function (placeholder) {
    return placeholder.children.length > 0;
  });

  var data = Array.prototype.map.call(DOMElement, function (placeholder) {
    return {
      locale: placeholder.getAttribute('locale'),
      functions: placeholder.getAttribute('functions-title'),
      id: placeholder.getAttribute('associate-id'),
      src: placeholder.children[0].getAttribute('src'),
    }
  });

  return data;
}


var Component = (function () {

  function Results(props) {
    var wrapper = document.createElement('div');
    wrapper.innerHTML =
      '<div class="results">' +
      '<h2>' +
      props.title + '<span class="plusFilterProducts rotate-plus"></span> ' +
      '</h2>' +
      '<article class="g">' + props.children + '</div>' +
      '</article>';
    return wrapper.children[0];
  }

  function Card(props) {
    var wrapper = document.createElement('div');
    var url = (props.locale == 'it') ? props.it_url : props.url
    wrapper.innerHTML =
      '<a href=" ' + url + ' " class="g3 item-caption product-cover">' +
      '<div class="img-container">' +
      '<img src="' + props.coverImage + '" alt="">' +
      '</div>' +
      '<h5>' + props.title + '</h5>' +
      '<p><span>' + props.function + '</span></p>' +
      '</a>';
    return wrapper.children[0];
  }

  return {
    Results: Results,
    Card: Card
  }

})();

/*********************** SMOOTH SCROLL MANAGER ****************************/

if (Util.el('.company')) {
  var companyLinks = ['#history', '#awards'];
  for (var i = 0; i < companyLinks.length; i++) {
    Util.el(companyLinks[i]).addEventListener('click', function (e) {
      SmoothScrollModule.smoothScrollTo((Util.el('#' + this.id + '-box').offsetTop + 700), scrollNode, 20);
    });
  }
}


if (Util.el('#product-detail-section')) {
  var detailLinks = ['#product-overview', '#data-sheet', '#downloads'];
  for (var i = 0; i < detailLinks.length; i++) {
    Util.el(detailLinks[i]) && Util.el(detailLinks[i]).addEventListener('click', function (e) {
      SmoothScrollModule.smoothScrollTo(window.innerHeight + (Util.el('#' + this.id + '-box').offsetTop - 160), scrollNode, 20);
    });
  }
}

// BACK TO TOP LINK IN FOOTER
Util.el('.top-link').addEventListener('click', function (e) {
  SmoothScrollModule.smoothScrollTo(0, scrollNode, 0);
});

// SCROLL DOWN LINK IN HOME
if (Util.el('#home')) {
  Util.el('.scroll-down').addEventListener('click', function (e) {
    SmoothScrollModule.smoothScrollTo(Util.el('#home').offsetTop, scrollNode, 20);
  });
}

// SCROLL DOWN LINK IN PRODUCT DETAIL PAGE
if (Util.el('#product-detail-section')) {
  Util.el('.scroll-down').addEventListener('click', function (e) {
    SmoothScrollModule.smoothScrollTo(Util.el('#product-detail-section').offsetTop, scrollNode, 20);
  });
}

// INSPIRATION BOX LINK
if (Util.el('.inspiration-ball')) {
  Util.el('.inspiration-ball').addEventListener('click', function (e) {

    var distanciator = (window.innerWidth > 768) ? 100 : 0;

    var distanceToGo = (Util.el('#socialFeedHome').offsetTop) + (document.getElementById('home').offsetTop);
    SmoothScrollModule.smoothScrollTo(distanceToGo - distanciator, document.body, 20);
  });
}

// SCROLL DOWN TO SEE SPEC REP AGENCY IN WTB
if (Util.el('#map')) {
  Util.el('#message-agent-not-in-map').addEventListener('click', function (e) {
    SmoothScrollModule.smoothScrollTo(Util.el('.store-list').offsetTop, scrollNode, 20);
  });
}


/******************* ERROR MANAGER - SUBSCRIBE TO NEWSLETTER PAGE *********************/

var submitBtn = Util.el('#submit');

if (ErrorsNewsletterModule.isInstanziable(submitBtn)) {

  submitBtn.addEventListener('click', function (e) {
    var inputName = Util.el('input.name');
    var inputSurname = Util.el('input.surname');
    var inputEmail = Util.el('input.email');
    var privacyLabel = Util.el('.privacy-label');
    var subscribeWarning = Util.el('.subscribe-warning-hidden');
    var emailWarning = Util.el('.error-email-value');
    var privacyCheckbox = Util.el('#privacy');

    ErrorsNewsletterModule.checkName(inputName, subscribeWarning, e);
    ErrorsNewsletterModule.checkSurname(inputSurname, subscribeWarning, e);
    ErrorsNewsletterModule.checkEmail(inputEmail, subscribeWarning, emailWarning, e);
    ErrorsNewsletterModule.checkPrivacy(privacyCheckbox, privacyLabel, subscribeWarning, e);
  });
}


/*********************** INSTAFEED *************************/

if (InstaFeedModule.isInstanziable()) {

  var productTitle = Util.el('#product-title');
  var productFeedTitle = Util.el('h3.feed-title');

  if (homepageElement) {
    InstaFeedModule.init({
      userId: 2238334836,
      accessToken: '2238334836.190696d.f05154c270284de593990709492b0b3a',
      get: 'user'
    })
      .run();
  } else if (productTitle) {
    InstaFeedModule.init({
      userId: 2238334836,
      accessToken: '2238334836.190696d.f05154c270284de593990709492b0b3a',
      get: 'tagged',
      tagName: productTitle.innerHTML.substring(0, productTitle.innerHTML.indexOf(' ')),
      clientId: '190696d32ecc42deac79488f5999aceb',
      error: function (err) {
        hideShowSeeMore({instafeedError: err});
        InstaFeedModule.onXHRHandler(err, {
          productTitle: productTitle,
          productFeedTitle: productFeedTitle,
        });
      }
    })
      .run();
  }

}

function hideShowSeeMore(config) {
  const noItems = Util.els('.recent-item').length === 0;
  const noFeedItems = config.instafeedError === 'No images were returned from Instagram';
  if (noItems && noFeedItems) {
    relatedWrapper.style.display = 'none';
  }
}


/*********************** LAZY LOAD - PRODUCTS LIST PAGE ****************************/

if (Util.el('.b-lazy') != null) {
  window.onload = function () {
    new Blazy({
      offset: 200,
      success: function () {
        if (window.myMsnry) {
          setTimeout(function () {
            window.myMsnry.layout();
          })
        }
      }
    });
  }
}


/************************* FLICKITY SLIDERS ************************************/

const baseCarouselOptions = {
  pageDots: false,
  lazyLoad: true,
  wrapAround: true,
  autoPlay: true,
  pauseAutoPlayOnHover: false,
};

let sliderDataSheetEuro;
let sliderDataSheetUsa;

if (Util.el('.main-carousel')) {
  new Flickity('.main-carousel', {...baseCarouselOptions, setGallerySize: false});
}

if (Util.el('.product-carousel')) {
  new Flickity('.product-carousel', baseCarouselOptions);
}

if (Util.el('.project-detail-carousel')) {
  new Flickity('.project-detail-carousel', baseCarouselOptions);
}

if (Util.el('.slider-data-sheet-product__euro')) {
  sliderDataSheetEuro = new Flickity('.slider-data-sheet-product__euro', getSliderDataSheetOptions());
}

if (Util.el('.slider-data-sheet-product__usa')) {
  sliderDataSheetUsa = new Flickity('.slider-data-sheet-product__usa', getSliderDataSheetOptions());
}

function getSliderDataSheetOptions() {
  return {
    lazyLoad: true,
    wrapAround: true,
    autoPlay: false,
    pageDots: false,
    selectedAttraction: 0.055,
    friction: 1,
    setGallerySize: false,
  };
}

/*********************** PRODUCT DETAIL DATA SHEET CUSTOM NAV ****************************/

if (sliderDataSheetEuro) {
  generateCellGroupForDataSheetSlider(Util.el('.dots-data-sheet-euro'), sliderDataSheetEuro);
}
if (sliderDataSheetUsa) {
  generateCellGroupForDataSheetSlider(Util.el('.dots-data-sheet-usa'), sliderDataSheetUsa);
}

function generateCellGroupForDataSheetSlider(cellsButtonGroup, slider) {
  const getChildren = cellsButtonGroup.getElementsByTagName('button');
  const cellButtons = Array.prototype.slice.call(getChildren);
  getChildren[0].classList.add('is-selected');

  // update buttons on select
  slider.on('select', () => {
    const previousSelectedButton = cellsButtonGroup.querySelector('.is-selected');
    const selectedButton = cellsButtonGroup.children[slider.selectedIndex];
    previousSelectedButton.classList.remove('is-selected');
    selectedButton.classList.add('is-selected');
  });

  // cell select
  cellsButtonGroup.addEventListener('click', (event) => slider.select(cellButtons.indexOf(event.target)));
}
/******** ADD 'not for the Americas market' in MORE SECTION IN LUCEPLANUSA ***********/
if( Util.isLuceplanUsa() && Util.el('#more-resources')){
  Util.el('#more-resources').innerHTML = `${Util.el('#more-resources').innerHTML} (not for the Americas market)`;
}

/********************************** WHERE TO BUY **************************************/
if( Util.isLuceplanUsa() && Util.el('#agents')){
  Util.el('#agents').style.display = 'none';
}


/*********************** SEE MORE RELATED PRODUCTS/PROJECTS ****************************/

const seeMoreBtn = Util.el('.see-more');

if (seeMoreBtn) {
  seeMoreBtn.addEventListener('click', () => {
    relatedWrapper.classList.toggle('open');
    Util.el('.plusRelatedProducts').classList.toggle('rotate-plus');
  });
}


/*********************** TENDINA ORDER PRODUCTS/PROJECTS ****************************/

const selectOrderProducts = Util.el('#orderProducts');
const selectOrderProjects = Util.el('#orderProjects');

if (selectOrderProducts) {
  selectOrderProducts.addEventListener('change', function () {
    if (selectOrderProducts.value === 'chronological') {
      Util.replaceLocation(`${window.location.pathname}?order=chronological`);
    }
    if (selectOrderProducts.value === 'alphabetical') {
      Util.replaceLocation(window.location.pathname);
    }
  });
  selectOption(selectOrderProducts, 'chronological', 1);
}

if (selectOrderProjects) {
  selectOrderProjects.addEventListener('change', function () {
    if (selectOrderProjects.value === 'alphabetical') {
      Util.replaceLocation(`${window.location.pathname}?order=alphabetical`);
    }
    if (selectOrderProjects.value === 'chronological') {
      Util.replaceLocation(window.location.pathname);
    }
  });
  selectOption(selectOrderProjects, 'alphabetical', 1);
}

function selectOption(select, valueOption, index) {
  select.options[index].selected = window.location.search.indexOf(valueOption) >= 0;
}


/*********************** NEWSLETTER ****************************/

const newsletterPopup = Util.el('#newsletter-popup');
let newsletterPopupIsClosed = false;

if (newsletterPopup) {
  Util.el('.close-newsletter').addEventListener('click', closeNewsletter);
  document.addEventListener('scroll', onScrollForNewsletter);
  onScrollForNewsletter();
}

function closeNewsletter() {
  styleHiddenNewsletter();
  newsletterPopupIsClosed = true;
}

function onScrollForNewsletter() {
  const currentPosition = scrollNode.scrollTop;
  const conditionHideNewsletter = currentPosition > 200;
  const conditionShowNewsletter = !newsletterPopupIsClosed && currentPosition === 0;
  conditionHideNewsletter && styleHiddenNewsletter();
  conditionShowNewsletter && styleShownNewsletter();
}

function styleShownNewsletter() {
  newsletterPopup.style.transform = 'translateY(0)';
  newsletterPopup.style.opacity = '1';
}

function styleHiddenNewsletter() {
  newsletterPopup.style.transform = 'translateY(134px)';
  newsletterPopup.style.opacity = '0';
}

/*********************** COOKIE MANAGER ****************************/
const productionDomains = ['www.luceplan.com', 'luceplan.com', 'www.luceplanusa.com', 'luceplanusa.com'];
const isProduction = productionDomains.includes(window.location.host);

const languagePopUp = Util.el('.language-splash-page');
const queryString = window.location.search;

const italiaButton = Util.el('.italia-splash-btn');
const internationalButton = Util.el('.international-splash-btn');
const americaButton = Util.el('.america-splash-btn');

const italiaMenuButton = Util.el('.italia-menu-btn');
const internationalMenuButton = Util.el('.international-menu-btn');
const americaMenuButton = Util.el('.america-menu-btn');

const isAmericaWebsite = Util.isLuceplanUsa();
const isItalianWebsite = window.location.pathname.indexOf('/it/home') >= 0;
const isFirstRedirectForCookies = queryString.indexOf('isFirstRedirectForCookies') >= 0;
const isFromSplashPage = queryString.indexOf('isFromSplashPage') >= 0;

const path = 'https://www.luceplan.com';
const pathUsa = 'https://www.luceplanusa.com';

const SITE_VERSION = {
  AMERICA: 'america',
  ITALIA: 'italia',
  INTERNATIONAL: 'international'
};

// HAMBURGER MENU BUTTONS
if (isProduction) {
  italiaMenuButton.addEventListener('click', onSetItaliaCookie);
  internationalMenuButton.addEventListener('click', onSetInternationalCookie);
  americaMenuButton.addEventListener('click', onSetAmericaCookie);
}

if (homepageElement && isProduction) {
  window.fetch('/get-siteversion-cookie')
    .then(res => res.json())
    .then(siteVisibleResponse => {
      const user = siteVisibleResponse;
      if (languagePopUp) {
        const hasPreventLanguage = queryString && queryString.indexOf('choosenSite') > -1;
        const hasRedirect = queryString.indexOf('redirected') > -1;
        if (!user && !hasPreventLanguage && !hasRedirect) {
          languagePopUp.style.display="flex";
          document.body.style.overflow = 'hidden';

          //SPLASH PAGE BUTTONS
          italiaButton.addEventListener('click', (e) => onSetItaliaCookie(e, true, true));
          internationalButton.addEventListener('click', (e) => onSetInternationalCookie(e, true, true));
          americaButton.addEventListener('click', (e) => onSetAmericaCookie(e, true, true));
        }
      }

      if (queryString.indexOf('redirected') < 0 && queryString.indexOf('choosenSite') < 0) {
        if (user === SITE_VERSION.AMERICA && !isAmericaWebsite) {
          Util.replaceLocation(pathUsa + "?redirected");
        }
        if (user === SITE_VERSION.ITALIA && !isItalianWebsite) {
          Util.replaceLocation(path + "/it/home?redirected");
        }
        if (user === SITE_VERSION.INTERNATIONAL && (isItalianWebsite || isAmericaWebsite)) {
          Util.replaceLocation(path + "?redirected");
        }
      }
    })
    .catch(e => console.log('/get-siteversion-cookie error: ', e));

  if (queryString.indexOf('fromAmerica') >= 0 && isFirstRedirectForCookies) {
    if (!isItalianWebsite) {
      goToCookiePage(SITE_VERSION.INTERNATIONAL, false, isFromSplashPage);
    } else {
      goToCookiePage(SITE_VERSION.ITALIA, false, isFromSplashPage);
    }
  }

  if (isAmericaWebsite && isFirstRedirectForCookies) {
    goToCookiePage(SITE_VERSION.AMERICA, false, isFromSplashPage);
  }

  const cookiesWarning = Util.el('.splash-page__abstract');
  if (!navigator.cookieEnabled) {
    cookiesWarning.style.display = 'block';
  }
}

function goToCookiePage(siteVersion, isFirstRedirectForCookies, overwriteSiteVersionCookie = false, isFromSplashPage = false) {
  Util.replaceLocation(`/cookie?siteVersion=${siteVersion}&isAmericaWebsite=${isAmericaWebsite}&isFirstRedirectForCookies=${isFirstRedirectForCookies}&overwriteSiteVersionCookie=${overwriteSiteVersionCookie}&isFromSplashPage=${isFromSplashPage}`);
}

function onSetItaliaCookie(e, overwriteSiteVersionCookie = false, isFromSplashPage = false) {
  e.preventDefault();
  goToCookiePage(SITE_VERSION.ITALIA, true, overwriteSiteVersionCookie, isFromSplashPage);
}

function onSetInternationalCookie(e, overwriteSiteVersionCookie = false, isFromSplashPage = false) {
  e.preventDefault();
  goToCookiePage(SITE_VERSION.INTERNATIONAL, true, overwriteSiteVersionCookie, isFromSplashPage);
}

function onSetAmericaCookie(e, overwriteSiteVersionCookie = false, isFromSplashPage = false) {
  e.preventDefault();
  goToCookiePage(SITE_VERSION.AMERICA, true, overwriteSiteVersionCookie, isFromSplashPage);
}
