module.exports = (function () {
  function element(selector) {
    return document.querySelector(selector);
  }

  function elements(selectors) {
    return document.querySelectorAll(selectors);
  }

  function create(elem) {
    return document.createElement(elem)
  }

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function clearString(string) {
    if (string.indexOf('+') >= 0) {
      string = string.split('+').join(' ');
    }
    string = string.replace('%27', '\'');
    string = string.replace('%28', '(');
    string = string.replace('%29', ')');
    string = string.replace('%C3%AC', 'ì');
    return string;
  }

  function darkBody(searchResults) {
    if (searchResults) {
      document.body.style.background = '#333';
      element('header').classList.add('header-search-results');
    }
  }

  function placeholderSearch(searchBar, searchButton) {
    if (window.location.pathname.indexOf('/it') >= 0) {
      searchBar.placeholder = 'Cerca';
      searchButton.value = 'Cerca';
    } else {
      searchBar.placeholder = 'Type here to search';
      searchButton.value = 'Search';
    }
  }

  function clearStringFromAccents(string) {
    string = string.replace('ì', 'i');
    string = string.replace('è', 'e');
    string = string.replace('é', 'e');
    string = string.replace('&', 'e');
    return string;
  }

  function dynamicCopy(element) {
    if (element) {
      var currentYear = new Date().getFullYear();
      element.innerHTML = '© ' + currentYear + ' Luceplan';
    }
  }

  function replaceLocation(path) {
    window.location.replace(path);
  }

  function isLuceplanUsa() {
    return window.location.href.indexOf('luceplanusa') > -1;
  }


  return {
    el: element,
    els: elements,
    create: create,
    validateEmail: validateEmail,
    clearString: clearString,
    darkBody: darkBody,
    placeholderSearch: placeholderSearch,
    clearStringFromAccents: clearStringFromAccents,
    dynamicCopy: dynamicCopy,
    replaceLocation: replaceLocation,
    isLuceplanUsa: isLuceplanUsa,
  }
})();
